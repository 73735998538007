import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DataGridPayLoad,
    DataGridState,
    DataGridStore,
} from 'common/types/datagrid';
import _, { isEmpty } from 'lodash';

const defaultState: DataGridState = {
    data: [],
    totalCount: 0,
    activePaginationPage: 1,
    dataLoading: false,
    initialized: false,
    activeFilters: [],
    activeQuery: {},
    columnVisibility: {},
    columnDimensions: {},
    pinnedColumns: {
        left: [],
        right: [],
    },
    density: 'compact' as const,
    sortModel: [],
    rowGroupingModel: [],
    columnOrdering: [],
    activeId: 0,
    filterModel: { items: [] },
};

const initialState: DataGridStore = {
    ADMIN_ORDER: defaultState,
    ADMIN_ORDER_RULE: defaultState,
    ADMIN_PROJECT: defaultState,
    ADMIN_TEMPLATE_QUOTE: defaultState,
    ADMIN_TEMPLATE_CLIENT: defaultState,
    ADMIN_TEMPLATE_CHECKLIST: defaultState,
    ADMIN_QUOTE_EQUIPMENT_ONLY: defaultState,
    ADMIN_FINANCE_SYNC_TASK: defaultState,
    ADMIN_FINANCE_SALES_INVOICE: defaultState,
    ADMIN_FINANCE_PURCHASE_INVOICE: defaultState,
    ADMIN_FINANCE_POSTAL_CODE_LABOR_RATE: defaultState,
    ADMIN_FINANCE_CONTRACTOR_LABOR_RATE: defaultState,
    ADMIN_FINANCE_CLIENT_LABOR_RATE: defaultState,
    ADMIN_FINANCE_PROJECT_LABOR_RATE: defaultState,
    ADMIN_CONTRACTOR_FILE: defaultState,
    AHRI: defaultState,
    DISTRIBUTION_INVENTORY: defaultState,
    SAVED_ORDER: defaultState,
    WARRANTY_OWNER: defaultState,
    TAX_TRANSACTION_HISTORY: defaultState,
};

export const dataGridStateSlice = createSlice({
    name: 'dataGridState',
    initialState,
    reducers: {
        resetGridState: () => {
            return initialState;
        },
        initializeGridState: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'initialState'>
            >
        ) => {
            const { gridName, initialState: _initialState } = action.payload;
            if (!state[gridName]?.initialized) {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    ...(_initialState && _initialState),
                    initialized: true,
                };
            }
        },
        deleteGridState: (state, action: PayloadAction<DataGridPayLoad>) => {
            const { gridName } = action.payload;
            state[gridName] = { ...initialState[gridName] };
        },
        loadGridState: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'initialState'>
            >
        ) => {
            const { gridName, initialState: selectedState } = action.payload;
            state[gridName] = {
                ...(initialState[gridName] && initialState[gridName]),
                ...(selectedState && selectedState),
            };
        },
        setColumnVisibility: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'columnVisibility'>
            >
        ) => {
            const { gridName, columnVisibility } = action.payload;
            if (state[gridName]) {
                state[gridName].columnVisibility = columnVisibility;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    columnVisibility,
                };
            }
        },
        setPinnedColumns: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'pinnedColumns'>
            >
        ) => {
            const { gridName, pinnedColumns } = action.payload;
            if (state[gridName]) {
                state[gridName].pinnedColumns = pinnedColumns;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    pinnedColumns,
                };
            }
        },
        setSortModel: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'sortModel'>
            >
        ) => {
            const { gridName, sortModel } = action.payload;
            if (state[gridName]) {
                state[gridName].sortModel = sortModel;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    sortModel,
                };
            }
        },
        setDensity: (
            state,
            action: PayloadAction<Pick<DataGridPayLoad, 'gridName' | 'density'>>
        ) => {
            const { gridName, density } = action.payload;
            if (state[gridName]) {
                state[gridName].density = density;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    density,
                };
            }
        },
        setColumnOrdering: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'columnOrdering'>
            >
        ) => {
            const { gridName, columnOrdering } = action.payload;
            if (state[gridName]) {
                state[gridName].columnOrdering = columnOrdering;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    columnOrdering,
                };
            }
        },
        setColumnDimensions: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'columnDimensions'>
            >
        ) => {
            const { gridName, columnDimensions } = action.payload;
            if (state[gridName]) {
                state[gridName].columnDimensions = columnDimensions;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    columnDimensions,
                };
            }
        },
        setActiveFilters: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'activeFilters'>
            >
        ) => {
            const { gridName, activeFilters } = action.payload;
            if (state[gridName]) {
                state[gridName].activeFilters = [...activeFilters];
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    activeFilters,
                };
            }
        },
        setRowGroupingModel: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'rowGroupingModel'>
            >
        ) => {
            const { gridName, rowGroupingModel } = action.payload;
            if (state[gridName]) {
                state[gridName].rowGroupingModel = rowGroupingModel;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    rowGroupingModel,
                };
            }
        },
        setGridViewActiveId: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'activeId'>
            >
        ) => {
            const { gridName, activeId } = action.payload;
            if (state[gridName]) {
                state[gridName].activeId = activeId;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    activeId,
                };
            }
        },
        setData: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'data' | 'reset'>
            >
        ) => {
            const { gridName, data = [], reset = false } = action.payload;
            if (state[gridName]) {
                state[gridName].data =
                    reset || !state[gridName]?.data
                        ? _.uniqBy([...data], 'id')
                        : _.uniqBy([...state[gridName].data, ...data], 'id');
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    data: _.uniqBy([...data], 'id'),
                };
            }
        },
        setDataLoading: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'dataLoading'>
            >
        ) => {
            const { gridName, dataLoading } = action.payload;
            if (state[gridName]) {
                state[gridName].dataLoading = dataLoading;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    dataLoading,
                };
            }
        },
        setTotalCount: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'totalCount'>
            >
        ) => {
            const { gridName, totalCount } = action.payload;
            if (state[gridName]) {
                state[gridName].totalCount = totalCount;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    totalCount,
                };
            }
        },
        setActiveQuery: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'activeQuery'>
            >
        ) => {
            const { gridName, activeQuery } = action.payload;
            if (state[gridName]) {
                state[gridName].activeQuery = {
                    ...(!isEmpty(state[gridName].activeQuery) &&
                        state[gridName].activeQuery),
                    ...activeQuery,
                };
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    activeQuery: { ...activeQuery },
                };
            }
        },
        setActivePaginationPage: (
            state,
            action: PayloadAction<
                Pick<DataGridPayLoad, 'gridName' | 'activePaginationPage'>
            >
        ) => {
            const { gridName, activePaginationPage } = action.payload;
            if (state[gridName]) {
                state[gridName].activePaginationPage = activePaginationPage;
            } else {
                state[gridName] = {
                    ...(initialState[gridName] && initialState[gridName]),
                    activePaginationPage,
                };
            }
        },
    },
});

export const {
    resetGridState,
    initializeGridState,
    loadGridState,
    deleteGridState,
    setColumnVisibility,
    setPinnedColumns,
    setSortModel,
    setColumnDimensions,
    setColumnOrdering,
    setDensity,
    setActiveFilters,
    setRowGroupingModel,
    setGridViewActiveId,
    setTotalCount,
    setData,
    setDataLoading,
    setActiveQuery,
    setActivePaginationPage,
} = dataGridStateSlice.actions;

export default dataGridStateSlice.reducer;
